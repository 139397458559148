import React, { Suspense, lazy, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// FOR GLOBAL
import Spinner from "./global/components/Spinner";
import BodyGlobal from "./global/components/BodyGlobal";
import {
  setJWT,
  getLocalStorage,
  setLocalStorage,
  setUnAuthInterceptor,
  removeLocalStorage,
} from "./global/axios";
import { PrivateRoute, LoginRoute } from "./global/utils";
import NotFound from "./global/components/NotFound";

// FOR ADMIN
const Login = lazy(() => import("./admin/pages/Login"));
const AdminHome = lazy(() => import("./admin/pages/AdminHome"));
const Activities = lazy(() => import("./admin/pages/Activities"));
const Collaborations = lazy(() => import("./admin/pages/Collaborations"));
const Educations = lazy(() => import("./admin/pages/Educations"));
const Schedule = lazy(() => import("./admin/pages/Schedule"));
const SaleProducts = lazy(() => import("./admin/pages/SaleProducts"));
const Events = lazy(() => import("./admin/pages/Events"));
const Documents = lazy(() => import("./admin/pages/Documents"));
const AdminNews = lazy(() => import("./admin/pages/News"));

// FOR CLIENT
const Home = lazy(() => import("./client/pages/Home"));
const About = lazy(() => import("./client/pages/About"));
const History = lazy(() => import("./client/pages/History"));
const HistoryActivities = lazy(() =>
  import("./client/pages/HistoryActivities")
);
const HistoryFiliales = lazy(() => import("./client/pages/HistoryFiliales"));
const HistoryJD = lazy(() => import("./client/pages/HistoryJD"));
const HistoryCollaborations = lazy(() =>
  import("./client/pages/HistoryCollaborations")
);
const InformationEducation = lazy(() =>
  import("./client/pages/InformationEducation")
);
// const InformationDeafCulture = lazy(() =>
//   import("./client/pages/InformationDeafCulture")
// );
const InformationClassSchedule = lazy(() =>
  import("./client/pages/InformationClassSchedule")
);
const InformationSaleProducts = lazy(() =>
  import("./client/pages/InformationSaleProducts")
);
const InformationEvents = lazy(() =>
  import("./client/pages/InformationEvents")
);
const InformationDocuments = lazy(() =>
  import("./client/pages/InformationDocuments")
);
const News = lazy(() => import("./client/pages/News"));
const Contact = lazy(() => import("./client/pages/Contact"));
const Body = lazy(() => import("./client/components/Body"));
const Donate = lazy(() => import("./client/pages/Donate"));

const App = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [isLoggedBackend, setIsLoggedBackend] = useState(false);
  const [isJwt, setIsJwt] = useState(getLocalStorage("jwt") || "");

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    handlerJWT();
  });

  const handlerJWT = () => {
    if (isJwt !== "") {
      setJWT(isJwt);
      setIsLogged(true);
    }
    setIsLoggedBackend(true);
    setUnAuthInterceptor(setLoggoutData);
  };

  const setLogginData = (user, jwt) => {
    setIsJwt(jwt);
    setIsLogged(true);
    setLocalStorage("jwt", jwt);
    setLocalStorage("user", JSON.stringify(user));
    setJWT(jwt);
  };

  const setLoggoutData = () => {
    setIsJwt("");
    setIsLogged(false);
    setJWT("");
    removeLocalStorage("jwt");
    removeLocalStorage("user");
  };

  const auth = {
    isLogged: isLogged,
    login: setLogginData,
    logout: setLoggoutData,
  };

  if (!isLoggedBackend)
    return (
      <BodyGlobal text="Cargando información desde servidor de ASH ...">
        <Spinner />
      </BodyGlobal>
    );
  return (
    <Suspense
      fallback={
        <BodyGlobal text="Cargando información página web de ASH ...">
          <Spinner />
        </BodyGlobal>
      }
    >
      <Router>
        <Routes>
          {/* FOR CLIENT */}
          <Route path="/" element={<Home />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/about" element={<About />} />
          <Route path="/history" element={<History />} />
          <Route path="/activities" element={<HistoryActivities />} />
          <Route path="/filiales" element={<HistoryFiliales />} />
          <Route path="/board-directors" element={<HistoryJD />} />
          <Route path="/collaborations" element={<HistoryCollaborations />} />
          <Route path="/education" element={<InformationEducation />} />
          {/* <Route path="/deaf-culture" element={<InformationDeafCulture />} /> */}
          <Route
            path="/class-schedule"
            element={<InformationClassSchedule />}
          />
          <Route path="/sale-products" element={<InformationSaleProducts />} />
          <Route path="/events" element={<InformationEvents />} />
          <Route path="/documents" element={<InformationDocuments />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />

          {/* FOR ADMIN */}
          <Route
            path="/admin"
            element={
              <PrivateRoute auth={auth}>
                <AdminHome auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/activities"
            element={
              <PrivateRoute auth={auth}>
                <Activities auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/collaborations"
            element={
              <PrivateRoute auth={auth}>
                <Collaborations auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/educations"
            element={
              <PrivateRoute auth={auth}>
                <Educations auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/schedule"
            element={
              <PrivateRoute auth={auth}>
                <Schedule auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/sale-products"
            element={
              <PrivateRoute auth={auth}>
                <SaleProducts auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/events"
            element={
              <PrivateRoute auth={auth}>
                <Events auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/documents"
            element={
              <PrivateRoute auth={auth}>
                <Documents auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/news"
            element={
              <PrivateRoute auth={auth}>
                <AdminNews auth={auth} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/login"
            element={
              <LoginRoute auth={auth}>
                <Login auth={auth} />
              </LoginRoute>
            }
          />

          <Route
            path="*"
            element={
              <Body title="Page Not Found">
                <NotFound />
              </Body>
            }
          />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
