import React from "react";
import "./style.css";
import image_navbar from "../../../client/assets/img/image_navbar.png";

const BodyGlobal = ({ children, text }) => {
  return (
    <>
      <section>
        <nav className="navbar-container">
          <nav className="navbar">
            <img
              src={image_navbar}
              className="navbar-background-img"
              alt="Logo"
            />
          </nav>
        </nav>
      </section>
      <div className="body-global-container">
        {children}
        <h4>{text}</h4>
      </div>
    </>
  );
};

export default BodyGlobal;
