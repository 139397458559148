import React from "react";

import { Navigate } from "react-router-dom";

//private route  component handling its children v2
export const PrivateRoute = ({ children, auth }) => {
  if (!auth.isLogged) return <Navigate to={"/admin/login"} />;
  if (auth.isLogged) return children;
};

//Si la url agrega login, alli sabra si esta logueado o no
export const LoginRoute = ({ children, auth }) => {
  if (!auth.isLogged) return children;
  if (auth.isLogged) return <Navigate to={"/admin"} />;
};
