import axios from "axios";
const axiosParams = {
  // baseURL: "http://localhost:3001",
  baseURL: "https://ashonduras.com",
  //Headers para los archivos
  // headers: {
  //   "cache-control": "no-cache",
  //   "Content-Type": "multipart/form-data",
  // },
  // Para esta sin necesidad los archivos
  headers: { "cache-control": "no-cache", "Content-Type": "application/json" },
};

export const publicAxios = axios.create(axiosParams);
publicAxios.defaults.headers.common["cache-control"] = "no-cache";
publicAxios.defaults.headers.post["Content-Type"] = "no-cache";
publicAxios.defaults.headers.put["Content-Type"] = "no-cache";

export const privateAxios = axios.create(axiosParams);
privateAxios.defaults.headers.common["cache-control"] = "no-cache";
privateAxios.defaults.headers.post["Content-Type"] = "no-cache";
privateAxios.defaults.headers.put["Content-Type"] = "no-cache";

export const setJWT = (jwt) => {
  privateAxios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export const setUnAuthInterceptor = (logoutHandler) => {
  privateAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // console.log(error);
      if (error.response) {
        switch (error.response.status) {
          case 401:
            logoutHandler();
            break;
          default:
            console.log(error);
        }
      } else {
        console.log(error);
      }
      return Promise.reject(error);
    }
  );
};

export const naxios = publicAxios;
export const paxios = privateAxios;

const localStorageAvailable = () => {
  let t = "t";
  try {
    localStorage.setItem(t, t);
    localStorage.removeItem(t);
    return true;
  } catch (e) {
    return false;
  }
};

export const getLocalStorage = (key) => {
  if (localStorageAvailable) {
    return localStorage.getItem(key);
  } else {
    return null;
  }
};

export const setLocalStorage = (key, value) => {
  if (localStorageAvailable) {
    localStorage.setItem(key, value);
    return true;
  } else {
    return false;
  }
};

export const removeLocalStorage = (key) => {
  if (localStorageAvailable) {
    localStorage.removeItem(key);
    return true;
  } else {
    return false;
  }
};
