import React from "react";
import ReactDOM from "react-dom/client";
import "./client/assets/css/fonts.css";
import "./client/assets/css/reset.css";
import "./client/assets/css/global.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <App />
  </HelmetProvider>
  // </React.StrictMode>
);
