import { Link } from "react-router-dom";
import { scrollTop } from "../../../global/components/Scroll";

import "./style.css";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>🚧NOT FOUND🚧</h1>
        <h2>¡Vaya! </h2>
        <h3>No encontramos la página que buscas</h3>
        <Link
          to="/"
          data-aos="zoom-in"
          data-aos-delay="400"
          data-aos-duration="1500"
          onClick={() => scrollTop()}
        >
          <button className="not-found-btn">Inicio de ASH</button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
